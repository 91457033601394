import React from "react";
import "./vipbenefitsmodal.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RenderMarkdown } from "../RenderMarkdown";

/**
 * Component for modal with VIP benefits on it
 * @param {string} nameOfClass - className value to wrap around Modal body
 * @param {Boolean} showModal - state value that controls if modal is showing or hidden
 * @param {function} setShowModal - anonymous function that controls showModal state value
 * @param {Boolean} showGoVIPButton - boolean flag indicating if Buy Pro button should be rendered
 * @param {string} utmLink - link to be used for Buy Pro button
 * 
 */
const VipBenefitsModal = ({
  nameOfClass,
  showModal,
  setShowModal,
  showGoVIPButton,
  utmLink,
}) => {

  const { t } = useTranslation();

  return (
    <Modal 
      show={showModal} 
      onHide={setShowModal} 
      animation={false} 
      centered={true} 
      dialogClassName="vipBenefitsModal" 
      size="xl"
    >
      <Modal.Header closeButton>
        <RenderMarkdown>
          { t("components.vipBenefitsModal.heading") }
        </RenderMarkdown>
      </Modal.Header>
      <Modal.Body>
        <div className={"vipBenefitsModalBody " + nameOfClass}>
          <RenderMarkdown>
            { t("components.vipBenefitsModal.message") }
          </RenderMarkdown>
          {showGoVIPButton
            && (
              <div className="buttonContainer">
                <a
                  className="genButton goVIPButton"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={utmLink}
                >
                  { t("common.goVIP") }
                </a>
              </div>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  );
  
};

export default VipBenefitsModal;

