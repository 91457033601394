import React, { useState } from "react";

export const storyDatabase = [
  {
    name: "Politics in an Uber",
    storyNumber: "2",
    date: "12.13.2024",
    relateDrillTopic: "Taking a taxi",
    relatedDrillURL: "https://speak.beepboop.us/catalog/study/SpanishConversational506L2",
  },
  {
    name: "First day in Atlangatepec",
    storyNumber: "1",
    date: "12.09.2024",
    relateDrillTopic: "Describing how you like a person",
    relatedDrillURL: "https://speak.beepboop.us/catalog/study/SpanishConversational603L2",
  },
];
export const currentStoryName = storyDatabase[0].name;
export const currentStoryNumber = storyDatabase[0].storyNumber;
export const currentStoryNumberAndName = `Story ${currentStoryNumber}: ${currentStoryName}`;

export const StoryDetail = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="version-history-container">
      <h2 className="version-history-label">
        <div 
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: "pointer" }}
        >
          Story {storyDatabase[0].storyNumber}: {currentStoryName} 
          {isExpanded ? " ▼" : " ▶"}
        </div>
      </h2>
      {isExpanded && (
        <div className="version-details-container">
          <h2 className="version-history-label">Story Database</h2>  
          {storyDatabase.map((ver, index) => (
            <div key={index} className="version-entry-template">
              <div className="title">Story {ver.storyNumber}: {ver.name}</div>
              <ul className="story-details-list">
                <li className="story-detail-item">Release Date: {ver.date}</li>
                <li className="story-detail-item">
                  Related Drill Topic: 
                  <a href={ver.relatedDrillURL} target="_blank" rel="noopener noreferrer">
                    {ver.relateDrillTopic}
                  </a>
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
