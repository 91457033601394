const storyText = [
  {
    "text": "<speak>El auto de Mateo **E1:Mateo's car** se detuvo **E3:stopped** **E1:stopped** frente al **E1:in front of** edificio moderno **E3:modern building** **E1:modern building** de TechNova **E1:of TechNova** María esperaba **E1:María was waiting** en la entrada **E1:at the entrance** Él sonrió ampliamente **E1:He smiled widely** mientras bajaba la ventana **E1:as he rolled down the window** **E2:Mateo's car stopped in front of TechNova's modern building. María was waiting at the entrance. He smiled widely as he rolled down the window.**</speak>",
    "speaker": "esUSStudioMaleB",
    "character": "narrator",
    "isSSML": true,
  },
  {
    "text": "<speak>¡Hola, buenos días **E1:Hello, good morning** ¿María, verdad **E1:María, right** Soy Mateo **E1:I am Mateo** tu conductor de Uber **E3:driver** **E1:your Uber driver** **E2:Hello, good morning! María, right? I am Mateo, your Uber driver.**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
  {
    "text": "<speak>Sí, así es **E1:Yes, that's right** Gracias **E1:Thank you** Tengo prisa **E1:I am in a hurry** porque voy a almorzar **E3:to have lunch** **E1:because I am going to have lunch** con mi nuevo jefe **E1:with my new boss** Es en el centro **E1:It is downtown** Me preocupa llegar tarde **E1:I am worried about being late** No quiero **E1:I don’t want** que piense mal de mí **E1:him to think badly of me** **E2:Yes, that's right. Thank you. I am in a hurry because I am going to have lunch with my new boss. It is downtown. I am worried about being late. I don’t want him to think badly of me.**</speak>",
    "speaker": "esUSNewsFemaleF",
    "character": "maria",
    "isSSML": true,
  },
  {
    "text": "<speak>Sí hay tráfico **E1:There is traffic** Tomaré **E1:I will take** un atajo **E3:shortcut** entonces **E1:a shortcut then** **E2:There is traffic. I will take a shortcut then.**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
  {
    "text": "<speak>Mientras conducía **E1:While he was driving** Mateo señaló **E1:Mateo pointed** un gran letrero **E3:sign** **E1:to a big sign** hacia el aeropuerto **E1:towards the airport** **E2:While he was driving, Mateo pointed to a big sign towards the airport.**</speak>",
    "speaker": "esUSStudioMaleB",
    "character": "narrator",
    "isSSML": true,
  },
  {
    "text": "<speak>Mira **E1:Look** ahí está el aeropuerto **E1:there is the airport** ¿Sabías que lo construyeron **E1:Did you know they built it** con dinero muy sospechoso **E3:suspicious** **E1:with very suspicious money** Sofía López **E1:Sofía López** nuestra querida alcaldesa **E3:mayor** **E1:our dear mayor** salió ganando muchísimo **E3:made out with a lot** **E1:made out with a lot** Fue un proyecto muy polémico **E3:controversial** **E1:It was a very controversial project** **E2:Look, there is the airport. Did you know they built it with very suspicious money? Sofía López, our dear mayor, made out with a lot. It was a very controversial project.**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
  {
    "text": "<speak>María frunció el ceño **E1:María frowned** claramente estaba incómoda **E1:She was clearly uncomfortable** con la dirección **E3:direction** **E1:with the direction** de la conversación **E1:of the conversation** Preferiría no hablar de política **E1:I’d rather not talk about politics** ¿Qué te gusta hacer Mateo **E1:What do you like to do Mateo** **E2:María frowned. She was clearly uncomfortable with the direction of the conversation. I’d rather not talk about politics. What do you like to do, Mateo?**</speak>",
    "speaker": "esUSStudioMaleB",
    "character": "narrator",
    "isSSML": true,
  },
  {
    "text": "<speak>Ah me encanta la música **E1:Ah I love music** Antes tenía una banda **E1:I used to have a band** ¿sabes **E1:you know** Tocaba la guitarra **E1:I played guitar** Ahora solo la toco **E1:Now I just play it** para mis hijos **E1:for my kids** Pero todavía sueño **E1:But I still dream** con volver a los escenarios **E3:stages** **E1:of getting back on stage** algún día **E1:one day** **E2:Ah, I love music. I used to have a band, you know? I played guitar. Now I just play it for my kids. But I still dream of getting back on stage one day.**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
  {
    "text": "<speak>Eso suena increíble **E1:That sounds amazing** ¿Qué tipo de música tocas **E1:What kind of music do you play** **E2:That sounds amazing. What kind of music do you play?**</speak>",
    "speaker": "esUSNewsFemaleF",
    "character": "maria",
    "isSSML": true,
  },
  {
    "text": "<speak>De todo un poco **E1:A little bit of everything** Pero tengo debilidad **E3:weakness** **E1:But I have a soft spot** por las baladas románticas **E1:for romantic ballads** La gente las pide mucho **E1:People request them a lot** en las fiestas **E1:at parties** **E2:A little bit of everything. But I have a soft spot for romantic ballads. People request them a lot at parties.**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
  {
    "text": "<speak>El auto llegó **E1: The car arrived** al centro del pueblo. **E1:at the town center.** Las calles eran empedradas **E3:cobblestone** **E1:The streets were cobblestone** y había puestos coloridos. **E3: colorful stalls.** **E1:and there were colorful stalls** María bajó del auto **E1:María got out of the car** con una sonrisa **E1:with a smile** **E2:The car arrived at the town center. The streets were cobblestone and there were colorful stalls. María got out of the car with a smile.**</speak>",
    "speaker": "esUSStudioMaleB",
    "character": "narrator",
    "isSSML": true,
  },
  {
    "text": "<speak>Gracias por el viaje Mateo **E1:Thanks for the ride Mateo** ¡Buena suerte con tu música **E1:Good luck with your music** **E2:Thanks for the ride, Mateo. Good luck with your music!**</speak>",
    "speaker": "esUSNewsFemaleF",
    "character": "maria",
    "isSSML": true,
  },
  {
    "text": "<speak>Gracias María **E1:Thanks María** Que disfrutes el centro **E1:Enjoy downtown** ¡Y prueba **E3:try** las quesadillas del mercado **E1:And try the quesadillas at the market** **E2:Thanks, María. Enjoy downtown. And try the quesadillas at the market!**</speak>",
    "speaker" : "esUSPolyglotMale1",
    "character": "mateo",
    "isSSML": true,
  },
];

export default storyText;
