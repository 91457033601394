import React, { useState } from "react";

export const versions = [
  {
    version: "v0.3.0",
    date: "12.13.2024",
    changes: [
      "Includes new story using phrases from the drill topic: Taking a taxi",  
      <>
        New Translation Mix feature: AI-powered translation system with four progressive learning stages:
        <ul className="version-change-sub-list">
          <li>Stage 1: Long Spanish sentences broken into smaller segments, each followed by English translation</li>
          <li>Stage 2: Complete Spanish sentences followed by full English translations</li>
          <li>Stage 3: Only complex Spanish words translated to English</li>
          <li>Stage 4: Spanish-only mode with no translations</li>
        </ul>
      </>,
    ],
  },
  {
    version: "v0.2.0",
    date: "12.11.2024",
    changes: [
      "Added: Standed header logo, back button, and menu",
      "Standardized: Slider bars for language mix",  
      "Fixed: Formatting for 'Take Quiz' button where it loaded left aligned",
    ],
  },
  {
    version: "v0.1.0",
    date: "12.10.2024",
    changes: [
      "Voice Fix: Switched to Google Neural2 voices to resolve audio playback issues (previous Journey voices were hitting API limits)",
      "Added: Fast forward/rewind (3s) buttons",
      "Added: Playback speed control",
      "Removed: Stop button",
      "Added: Version history tracker so you can see how feedback has been incorporated",
      "Added: Related Drill Topic link",
      "Enhanced: Readability on mobile devices",
    ],
  },
  {
    version: "v0.0.0",
    date: "12.08.2024 - Initial Release",
    changes: [
      "Story with realistic AI voices engaging in conversation about fictional village Atlangatepec",
      "Basic audio controls: Start, Stop, Pause",
      "Language Mix: Ability to toggle three levels of English translation: None, Half, and All",
    ],
  },
];

export const currentStoriesVersion = versions[0].version;

export const VersionHistory = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="version-history-container">
      <h2 className="version-history-label">
        <div 
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: "pointer" }}
        >
          {currentStoriesVersion} 
          {isExpanded ? " Improvements ▼" : " Improvements ▶"}
        </div>
      </h2>
      {isExpanded && (
        <div className="version-details-container">
          <div className="details-text">
            Beepboop Stories is an AI-driven Spanish learning tool that uses storytelling and voice technology to enhance listening skills
            <a href="https://friends.beepboop.us/p/introducing-beepboop-stories-v000" target="_blank" rel="noopener noreferrer">
              > Learn More
            </a>
          </div>
          <h2 className="version-history-label">Version History</h2>  
          {versions.map((ver) => (
            <div key={ver.version} className="version-entry-template">
              <div className="title">{ver.date} - {ver.version}:</div>
              <ul className="changes-list">
                {ver.changes.map((change, index) => (
                  <li key={index} className="change-item">
                    {change}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
