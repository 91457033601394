import React, { useState } from "react";
import VipBenefitsModal from "../../components/VipBenefitsModal";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
} from "react-router-dom";

import config from "../../configs/activebuildconfig.json";
const {
  KNOWLEDGE_BASE_URL_MAP,
  PLAY_ROOT_URL,
  PURCHASE_URL_MAP,
  RELEASE_NOTES_URL,
  SOCIAL_LEARNING_GROUP_JOIN_URL,
} = config;

import { getCurrentUser } from "../../context/UserContext";

import NavbarQuoteArea from "./NavbarQuoteArea";

/**
 * Renders the menu (the thing that expands when hamburger icon is clicked) of
 * the mobile nav bar
 *
 * @param { String } pageName - Name of the page to display in center of header
 * @param { String } returnFrom - name of page that should be passed as a
 * "from" state
 * @param { Boolean } showGoVIPButton - true if student is non-VIP and
 * MobileNavbar has received a displayGoVIPButton flag
 * @param { Boolean } showMenu - true if this menu should be displalyed, false
 * if it should be hidden
 * @param { String } studentEmail
 * @param { String } studentName
 * @returns
 */
const MobileNavbarMenu = ({
  pageName,
  returnFrom,
  showGoVIPButton,
  showMenu,
  studentEmail,
  studentName,
}) => {
  // Add state for Stories modal
  const [showStoriesModal, setShowStoriesModal] = useState(false);
  
  /*
   * HOOKS
   */

  // Translation function
  const { t } = useTranslation();
  const content = t(
    "components.mobileNavBar.menuItems",
    { returnObjects: true },
  );

  // Used to conditionally hide links that we aren't ready for Spanish speakers (English learners) to access
  // TODO: Be sure to un-hide links from the menu as we translate/internationalize more pages
  const { languageFluent, student_type } = getCurrentUser();
  const spanishSpeaker = languageFluent === "es";
  
  // Get user type from context
  const isRestrictedUser = student_type === "ACTIVE" || student_type === "TRIAL";
  
  /*
   * CONSTANT
   */

  const utmLinkMenu = PURCHASE_URL_MAP[languageFluent] + "?utm_source=studentportal&utm_medium=menu&utm_campaign=purchase-" + pageName?.toLowerCase() + "page";

  // Handler for Stories link click
  const handleStoriesClick = () => {
    if (isRestrictedUser) {
      setShowStoriesModal(true);
    }
  };

  /*
   * RENDER
   */

  return (
    <div className={"expandingMenuContainer" + (showMenu ? " openMenu" : "")}>
      <div className={"menuContents" + (showMenu ? " openContents" : "")}>
        <MobileNavbarMenuLink
          linkText={ content.book }
          linkTo="/book"
          linkState={{ from: returnFrom ? returnFrom : "home" }}
        />
        <MobileNavbarMenuLink
          linkText={ content.topicTree }
          linkTo="/topictree"
          linkState={{ from: returnFrom ? returnFrom : "home" }}
        />
        <MobileNavbarMenuLink
          linkText={ content.profile }
          linkTo="/profile"
          linkState={{ from: returnFrom ? returnFrom : "home" }}
        />
        {/* Empty div here is needed to maintain menu styling */} 
        {/* Only show Stories link if user is ACTIVE or TRIAL */}
        {isRestrictedUser ? (
          <Link
            className="menuContentsLink"
            to="#" // Using "#" since this is just triggering a modal
            onClick={handleStoriesClick}
            key={Math.random()}
          >
            {content.stories}
          </Link>
        ) : (
          <MobileNavbarMenuLink
            linkText={content.stories}
            linkTo="/stories"
            linkState={{ from: returnFrom ? returnFrom : "home" }}
          />
        )}
        {// Hide this link from Spanish Speakers until we update the game to match English and Spanish learners
          !spanishSpeaker
          && (
            <MobileNavbarMenuLink
              external
              linkText="Talkkaround Game (Beta)"
              linkTo={`${PLAY_ROOT_URL + "/login?name=" + studentName + "&email=" + studentEmail}`}
              linkState={{ from: returnFrom ? returnFrom : "home" }}
            />
          )
        }
        <MobileNavbarMenuLink
          external
          linkText={ content.knowledgeBase }
          linkTo={`${KNOWLEDGE_BASE_URL_MAP[languageFluent]}`}
          linkState={{ from: returnFrom ? returnFrom : "home" }}
        />
        {// Hide these links from Spanish Speakers until we can translate the pages and prep them for use by English learners
          !spanishSpeaker
          && (
            <>
              <MobileNavbarMenuLink
                external
                linkText={"News"}
                linkTo={`${RELEASE_NOTES_URL}`}
                linkState={{ from: returnFrom ? returnFrom : "home" }}
              />
              <MobileNavbarMenuLink
                external
                linkText={"Join our Social Learning Community"}
                linkTo={`${SOCIAL_LEARNING_GROUP_JOIN_URL}`}
                linkState={{ from: returnFrom ? returnFrom : "home" }}
              />
            </>
          )
        }
        {// VIP info is all in English, so hide from Spanish speakers
          showGoVIPButton && !spanishSpeaker
          && (
            <div className="menuContentsLink menuGoVIPContainer" >
              <a 
                className="genButton goVIPButton" 
                target="_blank" 
                rel="noreferrer noopener" 
                href={ utmLinkMenu }
              >
                Buy Pro
              </a>
              <NavbarQuoteArea />
            </div>
          )
        }
        {/* Empty div here is needed to maintain menu styling */}
        <div className="menuContentsLink" />
        <Link 
          className="logoutText" 
          to="/logout"
          state={{ from: returnFrom ? returnFrom : "home" }}
        >
          { content.logout }
        </Link>
        {/* Add VIP Benefits Modal */}
        <VipBenefitsModal
          nameOfClass={""}
          showModal={showStoriesModal}
          setShowModal={() => setShowStoriesModal(false)}
          showGoVIPButton={true}
          utmLink={PURCHASE_URL_MAP[languageFluent] + "?utm_source=studentportal&utm_medium=menu&utm_campaign=access-stories"}
        />
      </div>
    </div> 
  );
};

export default MobileNavbarMenu;

/**
 * Helper component to render a single link in this menu with styling class and
 * key gauranteeing uniqueness
 *
 * @param { Boolean } external [optional] - pass true for links outside of
 * Speak App
 * @param { String } linkTo - the path to which the link should navigate
 * @param { Object } linkState [optional] - state (if any) that should be
 * passed along to the new location
 * @param { String } linkText - the text to display as the link
 * @returns
 */
const MobileNavbarMenuLink = ({
  external = false,
  linkTo,
  linkState = null,
  linkText,
}) => {

  const location = useLocation();

  // On link click, check link location and take action if link path is pointed to current page
  const onLinkClick = ( event ) => {
    // Check if link path and current location are the same
    if ( event.target.pathname === location.pathname ) {
      // If so, force a reload of the current page
      window.location.reload();
    }
  };

  return external
    ? (
      <a
        className="menuContentsLink"
        href={ linkTo }
        key={ Math.random() }
        target="_blank"
      >
        { linkText }
      </a>
    )
    : (
      <Link
        className="menuContentsLink"
        to={ linkTo }
        state={ linkState }
        key={ Math.random() }
        onClick={ onLinkClick }
      >
        { linkText }
      </Link>
    );
};
