/**
 * Shows English translations with dashes for specified notation number
 * @param {Array|string} input - Either array of story objects or single text string
 * @param {number} englishNotation - The notation number to keep (any number)
 * @returns {Array|string} - Processed array of objects or string with translations
 */
export const textProcessing = (input, englishNotation) => {
  // Handle array of objects
  if (Array.isArray(input)) {
    return input.map(item => ({
      ...item,
      text: processString(item.text, englishNotation),
    }));
  }

  // Handle single string
  return processString(input, englishNotation);
};

/**
 * Helper function to process individual strings
 * @param {string} text - The text to process
 * @param {number} englishNotation - The notation number to keep
 * @returns {string} - Processed text
 */
const processString = (text, englishNotation) => {
  // Type validation
  if (typeof text !== "string") {
    console.error("Text processing error: Input text is", text);
    return "";
  }

  if (!text) {
    return "";
  }

  try {
    let processedText = text;
    
    if (englishNotation) {
      // First, handle the translations we want to keep (with double dashes)
      // Need to figure out how to get longer pause in between words using SSML. Make sure it supported in all voices used.
      processedText = processedText.replace(
        new RegExp(`\\*\\*E${englishNotation}:([^*]+)\\*\\*`, "g"),
        "<break time='400ms'/> $1 <break time='600ms'/>",
      );
      
      // Then remove other E-notation translations completely (including extra spaces)
      processedText = processedText.replace(
        new RegExp(`\\s*\\*\\*E(?!${englishNotation}:)[0-9]:[^*]+\\*\\*\\s*`, "g"),
        " ",
      );
    }
    else {
      // If no notation specified, remove all translations (including extra spaces)
      processedText = processedText.replace(/\s*\*\*E[0-9]:[^*]+\*\*\s*/g, " ");
    }

    // Clean up multiple spaces
    processedText = processedText.replace(/\s+/g, " ");

    return processedText.trim();
  }
  catch (error) {
    console.error("Error processing text:", error);
    return text;
  }
};
