import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ErrorFallback from "./components/ErrorFallback";
import ErrorMessage from "./components/ErrorMessage";
import HablaRedirect from "./components/HablaRedirect";
import LazyLoad from "./components/LazyLoad";
import RequireAuth from "./components/RequireAuth";
import VersionCheck from "./components/VersionCheck";

import activebuildconfig from "./configs/activebuildconfig.json";
export const inProd = activebuildconfig.APP_POINTER === "PROD";
export const version_string = inProd ?
  version.version :
  `${version.version} - ${activebuildconfig.APP_POINTER}`;

import { UserContextProvider } from "./context/UserContext";

// Pages imported with lazy loading to facilitate code-splitting
const BookingPage = lazy(() => import("./pages/BookingPage"));
const ClassSummary = lazy(() => import("./pages/ClassSummary"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const HomePage = lazy(() => import("./pages/HomePage"));
const JoinDrill = lazy(() => import("./pages/JoinDrill"));
const LogIn = lazy(() => import("./pages/LogIn"));
const LogOut = lazy(() => import("./pages/LogOut"));
const PaymentConfirmation = lazy(() => import("./pages/PaymentConfirmation"));
const NewStudentWelcome = lazy(() => import("./pages/NewStudentWelcome"));
const Profile = lazy(() => import("./pages/Profile"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Study = lazy(() => import("./pages/Study"));
const TakeDrill = lazy(() => import("./pages/TakeDrill"));
const TopicTreePage = lazy(() => import("./pages/TopicTreePage"));
const ViewDrillContent = lazy(() => import("./pages/Study/ViewDrillContent"));
const VocabReview = lazy(() => import("./pages/Study/VocabReview"));
const StoryPage = lazy(() => import("./pages/StoryPage"));

import { talkka } from "./services/logging";

import version from "./version/version.json";

// Importing these here so they're available throughout the app
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/common.css";

/**
 * Top-level component - renders routes and deploys analytics
 */
const App = () => {
  // On load, deploy analytics if we're in production
  useEffect(() => {
    // If we're in production, run the Google tags script
    if ( inProd ) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-661947892");
    }
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ ErrorFallback }
      onError={ (error, info) => talkka.error(error, info) }
      onReset={ () => window.location.reload() }
    >
      <UserContextProvider>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content="Beepboop: Learn Spanish by speaking online"
            />
            <meta name="theme-color" content="#F9F9F9" />
            <title>Speak with Beepboop</title>
            {// Only insert Google site tag in Production environment
              // Datalayer activation moved to on-load useEffect
              inProd
              && (
                <>
                  {/* <!-- Global site tag (gtag.js) - Google Ads: 661947892 --> */} <script async src="https://www.googletagmanager.com/gtag/js?id=AW-661947892" />
                </>
              )
            }
          </Helmet>
          <Router>
            <Routes>
              <Route
                path="/home"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <HomePage />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Navigate to="/home" />
                  </RequireAuth>
                }
              />
              <Route
                path="/book"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <BookingPage />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/topictree"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <TopicTreePage />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/catalog"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <TopicTreePage />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/catalog/study/viewDrillContent/:moduleId"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <ViewDrillContent />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/catalog/study/vocabReview/:moduleId"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <VocabReview />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/catalog/study/:moduleId"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <Study />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/class/:drillHash"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <ClassSummary />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/join/:id"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <JoinDrill />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/profile"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route 
                path="/speak/:drillHash"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <VersionCheck>
                        <TakeDrill />
                      </VersionCheck>
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/paymentConfirmation"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <PaymentConfirmation />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route 
                path="/newstudent"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <NewStudentWelcome />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="/forgotpassword"
                element={
                  <LazyLoad>
                    <ForgotPassword />
                  </LazyLoad>
                }
              />
              <Route
                path="/habla"
                element={ <HablaRedirect /> }
              />
              <Route
                path="/habla/*"
                element={ <HablaRedirect /> }
              />
              <Route
                path="/login"
                element={
                  <LazyLoad>
                    <LogIn />
                  </LazyLoad>
                }
              />
              <Route
                path="/logout"
                element={
                  <LazyLoad>
                    <LogOut />
                  </LazyLoad>
                }
              />
              <Route
                path="/signup"
                element={
                  <LazyLoad>
                    <SignUp />
                  </LazyLoad>
                }
              />
              <Route
                path="/stories"
                element={
                  <LazyLoad>
                    <RequireAuth>
                      <StoryPage />
                    </RequireAuth>
                  </LazyLoad>
                }
              />
              <Route
                path="*"
                element={
                  <VersionCheck>
                    <ErrorMessage />
                  </VersionCheck>
                }
              />
            </Routes>
          </Router>
        </HelmetProvider>
      </UserContextProvider>
    </ErrorBoundary>
  );
};

export default App;
