import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./StoryPage.css";
import { synthesizeTextToSpeech } from "../../services/datastore/index";
import { AudioPlayer } from "../../components/AudioPlayer";
import { getCurrentUser } from "../../context/UserContext";
import { VersionHistory, currentStoriesVersion } from "./VersionHistory";
import Loading from "../../components/Loading";
import ErrorFallback from "../../components/ErrorFallback";
import MobileNavbar from "../../components/MobileNavbar";
import { Slider } from "@mui/material";
import { textProcessing } from "./content/textProcessing";
import storyText from "./content/story2";
import { StoryDetail, currentStoryNumberAndName } from "./StoryDetail";

/**
 * Story Page component that allows users to read and listen to stories with adjustable language ratios
 */
const StoryPage = () => {
  // Constants and state
  const initialEnglishNotation = 1;
  const { student_type: studentType, email, first_name, level } = getCurrentUser();
  const [englishNotation, setEnglishNotation] = useState(initialEnglishNotation);
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  // Refs
  const audioPlayerRef = useRef(null);
  const sliderRef = useRef(null);
  
  // Get the previous page from the location state
  const location = useLocation();
  let previousPage = location?.state?.from ? location.state.from : "home";
  if (previousPage.toLowerCase() === "topictree") {
    previousPage = "home";
  }

  /**
   * Handles getting audio data for the story based on current Spanish percentage
   */
  const handleGetAudioData = async () => {
    try {
      console.log(storyText);
      console.log(textProcessing(storyText, englishNotation));
      const textParts = textProcessing(storyText, englishNotation);
      return await synthesizeTextToSpeech(textParts);
    }
    catch (error) {
      setShowError(true);
      console.error("Error getting audio data:", error);
    }
  };

  /**
   * Generates feedback form URL with user data as parameters
   */
  const getFeedbackFormUrl = () => {
    const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLSeV-1fgpEEv-x6klkHd4OZpiOtV2WqnuzJKoMt2cNg1RRUnkg/viewform";
    const emailParam = email ? `entry.2126690365=${encodeURIComponent(email)}` : "";
    const firstNameParam = first_name ? `entry.1434354865=${encodeURIComponent(first_name)}` : "";
    const versionParam = currentStoriesVersion ? `entry.263529819=${encodeURIComponent(currentStoriesVersion + currentStoryNumberAndName)}` : "";
    const levelParam = level ? `entry.195865747=${encodeURIComponent(level)}` : "";
    return `${baseUrl}?${emailParam}&${firstNameParam}&${versionParam}&${levelParam}&usp=sf_link`;
  };

  /**
   * Handles changes to the language ratio slider
   */
  const handleSliderChange = (e) => {
    try {
      const value = parseInt(e.target.value);
      console.log(value);
      // Stop current audio playback
      if (audioPlayerRef.current) {
        audioPlayerRef.current.stopAndReset();
      }
      
      setEnglishNotation(value);
    }
    catch (error) {
      setShowError(true);
      console.error("Error handling slider change: to ", error);
    }
  };

  // Redirect if student type is ACTIVE or TRIAL
  useEffect(() => {
    if (studentType === "ACTIVE" || studentType === "TRIAL") {
      window.location.href = "https://pro.beepboop.us/";
    }
  }, [studentType]);

  // Initialize slider value
  useEffect(() => {
    try {
      setIsLoading(false);
    }
    catch (error) {
      setShowError(true);
      console.error("Error initializing slider:", error);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (showError) {
    return <ErrorFallback resetErrorBoundary={() => window.location.reload()} />;
  }

  return (
    <MobileNavbar 
      pageName={"Stories"} 
      returnMessage={previousPage} 
      returnPathname={"/" + previousPage} 
      returnFrom={previousPage}
      displayAnnouncementBar = {false}
      hideCampaignsBar = {true}
    >
      <div className="story-page">
        <StoryDetail />
        <VersionHistory />
        <AudioPlayer 
          ref={audioPlayerRef}
          onGetAudioData={handleGetAudioData} 
        />

        <div className="spacing-div"></div>
        <div className="spacing-div"></div>

        <div className="slider-container">
          <span className="label label-left">
            More<br />English
          </span>
          <Slider
            ref={sliderRef}
            value={englishNotation}
            min={1}
            max={4}
            step={1}
            onChange={(_, value) => {
              handleSliderChange({ target: { value } });
            }}
            aria-label="Language Ratio"
          />
          <span className="label label-right">
            More<br />Spanish
          </span>
        </div>

        
        <div className="spacing-div"></div>
        <div className="spacing-div"></div>

        
        <div className="quiz-button-container">
          <a 
            href={getFeedbackFormUrl()} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="genButton studyButton"
          >
            Take Quiz
          </a>
        </div>
      </div>
    </MobileNavbar>
  );
};

export default StoryPage;
