import React from "react";
import { Slider } from "@mui/material";

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export const AudioSlider = ({ currentTime, duration, onSeek }) => {
  return (
    <div className="audio-slider">
      <span className="speed-label speed-label-left">
        {formatTime(currentTime)}
      </span>
      <Slider
        value={currentTime}
        min={0}
        max={duration}
        onChange={onSeek}
        aria-label="Audio Progress"
      />
      <span className="speed-label speed-label-right">
        {formatTime(duration)}
      </span>
    </div>
  );
};
