import React from "react";
import { IconButton, CircularProgress } from "@mui/material";
import { 
  PlayArrow, 
  Pause, 
  Stop, 
  FastForward, 
  FastRewind,
} from "@mui/icons-material";

export const AudioButtons = ({ 
  isPlaying, 
  isLoading, 
  onPlay, 
  onPause, 
  onStop, 
  onFastForward, 
  onRewind,
  showStopButton = false,
}) => {
  return (
    <div className="audio-buttons">
      <IconButton 
        onClick={onRewind}
        disabled={isLoading}
        aria-label="Rewind 3 seconds"
      >
        <FastRewind style={{ fontSize: "3rem" }} />
      </IconButton>

      <IconButton 
        onClick={() => {
          if (isPlaying) {
            onPause();
          }
          else {
            onPlay();
          }
        }}
        disabled={isLoading}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          isPlaying ? <Pause style={{ fontSize: "3rem" }} /> : <PlayArrow style={{ fontSize: "3rem" }} />
        )}
      </IconButton>

      {showStopButton && (
        <IconButton 
          onClick={onStop}
          disabled={isLoading}
          aria-label="Stop"
        >
          <Stop style={{ fontSize: "3rem" }} />
        </IconButton>
      )}

      <IconButton 
        onClick={onFastForward}
        disabled={isLoading}
        aria-label="Fast forward 3 seconds"
      >
        <FastForward style={{ fontSize: "3rem" }} />
      </IconButton>
    </div>
  );
};
