import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { AudioButtons } from "./AudioControls";
import { AudioSlider } from "./AudioSlider";
import { SpeedSlider } from "./SpeedSlider";
import "./audioPlayer.css";

export const AudioPlayer = forwardRef(({ onGetAudioData }, ref) => {
  const [audioElement, setAudioElement] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);

  useEffect(() => {
    if (audioElement) {
      const timeUpdateHandler = () => {
        setCurrentTime(audioElement.currentTime);
      };

      const loadedDataHandler = () => {
        setDuration(audioElement.duration);
      };

      const handleEnded = () => setIsPlaying(false);

      audioElement.addEventListener("timeupdate", timeUpdateHandler);
      audioElement.addEventListener("loadeddata", loadedDataHandler);
      audioElement.addEventListener("ended", handleEnded);
      
      return () => {
        audioElement.removeEventListener("timeupdate", timeUpdateHandler);
        audioElement.removeEventListener("loadeddata", loadedDataHandler);
        audioElement.removeEventListener("ended", handleEnded);
      };
    }
  }, [audioElement]);

  useEffect(() => {
    if (audioElement) {
      audioElement.playbackRate = playbackSpeed;
    }
  }, [audioElement, playbackSpeed]);

  const handlePlay = async () => {
    if (isLoading || isPlaying) return;
    
    try {
      setIsLoading(true);

      if (audioSrc && audioElement) {
        await audioElement.play();
        setIsPlaying(true);
        return;
      }

      // Get new audio data
      const audioBlob = await onGetAudioData();
      if (!audioBlob) {
        throw new Error("Failed to get audio");
      }

      const newAudioSrc = URL.createObjectURL(audioBlob);
      setAudioSrc(newAudioSrc);
      const audio = new Audio(newAudioSrc);
      setAudioElement(audio);
      await audio.play();
      setIsPlaying(true);
    }
    catch (error) {
      console.error("Error playing audio:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handlePause = () => {
    if (audioElement) {
      audioElement.pause();
      setIsPlaying(false);
    }
  };

  const handleStop = () => {
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleSeek = (_, newValue) => {
    if (audioElement) {
      audioElement.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  const handleSpeedChange = (_, newValue) => {
    setPlaybackSpeed(newValue);
  };

  /**
   * Handles fast forwarding the audio by 3 seconds
   * Ensures we don't exceed the audio duration and validates the audio element
   */
  const handleFastForward = () => {
    if (audioElement && !isNaN(audioElement.duration)) {
      const newTime = Math.min(
        audioElement.currentTime + 3,
        audioElement.duration,
      );
      audioElement.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  /**
   * Handles rewinding the audio by 3 seconds
   * Ensures we don't go below 0 seconds and validates the audio element
   */
  const handleRewind = () => {
    if (audioElement && !isNaN(audioElement.currentTime)) {
      const newTime = Math.max(audioElement.currentTime - 3, 0);
      audioElement.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    stopAndReset: () => {
      handleStop();
      // Reset visual time display
      setCurrentTime(0);
      setDuration(0);
      // Clear the audio element and source to force a reload next time
      setAudioElement(null);
      setAudioSrc(null);
    },
  }));

  return (
    <div className="audio-controls">
      <AudioButtons 
        isPlaying={isPlaying}
        isLoading={isLoading}
        onPlay={handlePlay}
        onPause={handlePause}
        onStop={handleStop}
        onFastForward={handleFastForward}
        onRewind={handleRewind}
      />
      <AudioSlider 
        currentTime={currentTime}
        duration={duration}
        onSeek={handleSeek}
      />
      <SpeedSlider 
        playbackSpeed={playbackSpeed}
        onSpeedChange={handleSpeedChange}
      />
    </div>
  );
});
