import React from "react";
import { Slider } from "@mui/material";

const formatSpeed = (speed) => {
  return `${speed.toFixed(1)}x`;
};

export const SpeedSlider = ({ playbackSpeed, onSpeedChange }) => {
  return (
    <div className="audio-slider">
      <span className="speed-label speed-label-left">
        {formatSpeed(playbackSpeed)}
      </span>
      <Slider
        value={playbackSpeed}
        min={0.5}
        max={2.0}
        step={0.1}
        onChange={onSpeedChange}
        aria-label="Playback Speed"
      />
      <span className="speed-label speed-label-right">
        2.0x
      </span>
    </div>
  );
};
